<template>
  <section>
    <h1 class="form-part__title mb-6">
      {{ $t('title') }}
    </h1>

    <t-form ref="form" @submit="onSubmit" @submit.prevent>
      <NameField :autofocus="true" @result="onResult" @change="onChange" />
      <EmailField @result="onResult" @change="onChange" />
      <AddressFormGroup
        :init-hidden-street-and-city="false"
        @result="onResult"
        @change="onChange"
      />
      <PhoneFormPart @result="onResult" @change="onChange" />
      <FormStepSubmitButton
        id="submitContactInformation"
        class="contact-information-button"
        :text="$t('button')"
        type="button"
        @click="onSubmit"
      />
    </t-form>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep.vue'
import NameField from 'chimera/all/components/form/fields/name/NameField'
import EmailField from 'chimera/all/components/form/fields/email/EmailField'
import PhoneFormPart from 'chimera/realEstateAgency/components/form/parts/phone/PhoneFormPart'
import AddressFormGroup from 'chimera/all/components/form/parts/address/AddressFormGroup'
import LeadSubmitPage from '~/pages/offertes-aanvragen/versturen'

export default {
  name: 'ContactInformationFormStep',

  components: {
    NameField,
    EmailField,
    AddressFormGroup,
    PhoneFormPart,
  },

  extends: AbstractFormStep,

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(LeadSubmitPage)
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Vul je gegevens aan om offertes te kunnen ontvangen",
    "button": "Aanvraag afronden"
  },
  "nl-BE": {
    "title": "Hoe kunnen onze immokantoren u bereiken?",
    "button": "Aanvraag afronden"
  }
}
</i18n>

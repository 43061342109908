<template>
  <div>
    <ContactInformationFormStep
      class="contact-information__form-step mb-8"
      :progress-value="progressValue"
    />
    <PrivacyMessage />
  </div>
</template>

<script>
import PrivacyMessage from 'chimera/all/components/PrivacyMessage'
import ContactInformationFormStep from '~/components/form/steps/contactInformation/ContactInformationFormStep'
import FormPage from '~/components/form/FormPage'

export default {
  name: 'ContactInformationFormPage',

  components: {
    ContactInformationFormStep,
    PrivacyMessage,
  },

  extends: FormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Contactgegevens',
      path: '/offertes-aanvragen/je-gegevens',
      progressValue: 90,
      checkoutStep: 1,
    }
  },
}
</script>
